import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../service/loader.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private activeRequests = 0;
    private excludedEndpoints: string[] = environment.excludedLoaderEndpoints || [];

    constructor(private loaderService: LoaderService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.shouldShowLoader(request)) {
            this.showLoader();
        }

        return next.handle(request).pipe(
            finalize(() => {
                if (this.shouldShowLoader(request)) {
                    this.hideLoader();
                }
            })
        );
    }

    private shouldShowLoader(request: HttpRequest<any>): boolean {
        return !this.excludedEndpoints.some(endpoint => request.url.includes(endpoint));
    }

    private showLoader(): void {
        if (this.activeRequests === 0) {
            this.loaderService.show();
        }
        this.activeRequests++;
    }

    private hideLoader(): void {
        this.activeRequests--;
        if (this.activeRequests === 0) {
            setTimeout(() => {
                this.loaderService.hide();
            }, 0);
        }
    }
}
