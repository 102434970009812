import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MotorMessageService} from '../../service/motor-message.service';

enum HttpStatusCode {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    INTERNAL_SERVER_ERROR = 500
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private motorMessage: MotorMessageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap({
                error: (err: HttpErrorResponse) => {
                    if (err.error) {
                        switch (err.status) {
                            case HttpStatusCode.UNAUTHORIZED:
                                this.motorMessage.openSnackBar('validation.userNotAuth');
                                break;
                            case HttpStatusCode.BAD_REQUEST:
                                this.handleBadRequest(err);
                                break;
                            case HttpStatusCode.INTERNAL_SERVER_ERROR:
                                this.motorMessage.openSnackBar('validation.internalError');
                                break;
                            default: {
                                console.error('Unexpected error:', err);
                                this.motorMessage.openSnackBar('validation.internalError');
                            }
                        }
                    }
                }
            })
        );
    }

    private handleBadRequest(err: HttpErrorResponse): void {
        if (err.error.msg) {
            this.motorMessage.openSnackBar(err.error.msg);
        } else if (err.error.data) {
            const errorData = err.error.data.error;
            if (errorData.label) {
                this.motorMessage.openSnackBar(errorData.label, errorData.param);
            } else {
                const message = typeof err.error === 'string' ? JSON.parse(err.error).msg : err.error.msg;
        this.motorMessage.openSnackBar(message);
      }
    }
  }
}
