<div class="header-inner">
    <div class="d-flex align-items-center">
        <button mat-icon-button aria-label="Menu" class="d-flex d-md-none me-2" (click)="onToggleMenu()">
            <mat-icon aria-hidden="false" aria-label="Menu">menu</mat-icon>
        </button>
        <a routerLink="/dashboard"><img src="../../../assets/images/logo-omotor-white.svg" alt=""></a>
    </div>

    <h3 class="responsive-title-nav-bar">{{currentUser.companyName}}</h3>

    <div class="d-flex" role="button" [matMenuTriggerFor]="menu">
        <div class="px-2">
            {{currentUser.name}}
            {{currentUser.lastname}}
        </div>
        <div><em class="fa fa-user"></em></div>
    </div>

    <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/account/profile">
            <mat-icon>settings</mat-icon>
            <span>{{'labels.myAccount' | translate}}</span>
        </button>
        <button mat-menu-item routerLink="/user-groups" *ngIf="accessGroup">
            <mat-icon>group</mat-icon>
            <span>{{'labels.userGroups' | translate}}</span>
        </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>{{'labels.logout' | translate}}</span>
        </button>
    </mat-menu>
</div>
<app-loader></app-loader>
