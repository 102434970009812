import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from "@abacritt/angularx-social-login";
import {provideTranslateService, TranslateLoader, TranslateService} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {InjectionToken, LOCALE_ID, NgModule, OnDestroy} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DatePipe, registerLocaleData} from "@angular/common";
import {BrowserModule} from '@angular/platform-browser';
import esAr from '@angular/common/locales/es-AR';
import ptBr from '@angular/common/locales/pt';
import {AuthenticationService} from "./pages/authentication/authentication.service";
import {AuthenticationModule} from './pages/authentication/authentication.module';
import {ErrorHandlerInterceptor} from './shared/interceptors/error.interceptor';
import {LoaderInterceptor} from './shared/interceptors/loader.interceptor';
import {TokenInterceptor} from "./shared/interceptors/token.interceptor";
import {AuthInterceptor} from "./shared/interceptors/auth.interceptor";
import {environment} from "../environments/environment";
import {LayoutsModule} from './layouts/layouts.module';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from "./shared/shared.module";
import {Subject, switchMap, takeUntil} from "rxjs";
import {AppComponent} from './app.component';
import {AuthGuard} from "./shared/guards/auth.guard";

registerLocaleData(ptBr, 'pt-BR');
registerLocaleData(esAr, 'es-AR');

const APP_LOCALE_ID = new InjectionToken<string>('APP_LOCALE_ID');
const endpointUrl: string = environment.endpointAPI + '/system/translate';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, `${endpointUrl}?lang=`, '');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        AuthenticationModule,
        SocialLoginModule,
        AppRoutingModule,
        BrowserModule,
        LayoutsModule,
        SharedModule
    ],
    providers: [
        AuthGuard,
        DatePipe,
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        {provide: LOCALE_ID, useExisting: APP_LOCALE_ID},
        {
            provide: APP_LOCALE_ID,
            useFactory: (translateService: TranslateService) => translateService.getDefaultLang() || 'pt-BR',
            deps: [TranslateService]
        },
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [{
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider(environment.facebookClientId)
                }]
            } as SocialAuthServiceConfig,
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule implements OnDestroy {
    private readonly destroy$ = new Subject<void>();

    constructor(
        protected translateService: TranslateService,
        private readonly authService: AuthenticationService
    ) {
        this.authService.getCurrentUserObservable().pipe(
            switchMap(user => {
                const lang = user.systemLocale?.lang ?? 'pt-BR';
                this.translateService.setDefaultLang(lang);
                return this.translateService.use(lang);
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
