import {Component} from '@angular/core';
import {DateUtils} from "../../shared/utils/date-utils";
import moment from "moment/moment";

@Component({
    selector: 'app-master-layout',
    templateUrl: './master-layout.component.html',
    styleUrls: ['./master-layout.component.scss'],
    standalone: false
})
export class MasterLayoutComponent {
    currentYear: number = moment(this.dateUtils.getToday()).year();
    statusMenu: string = 'closed';

    constructor(private dateUtils: DateUtils) {
    }

    onToggleMenu(event: string) {
        this.statusMenu = event;
    }
}
