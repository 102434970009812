<!-- START panel-->
<div class="container py-3">
    <div class="row mb-3">
        <div class="col">
            <h4 class="fw-normal">{{"labels.welcome" | translate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form [formGroup]="loginForm" (ngSubmit)="authentication()">
                <div class="form-group has-feedback">
                    <label for="email" class="form-label m-0">{{'labels.login' | translate}}</label>
                    <input formControlName="email" id="email" type="email" name="email" [email]="true"
                           autocomplete="off" required
                           class="form-control ng-valid-email ng-invalid ng-invalid-required">
                    <span class="text-danger"
                          *ngIf="!this.loginForm.get('email')?.valid && submitted">{{'errors.invalid.required' | translate}}</span>
                </div>

                <div class="form-group has-feedback mt-2">
                    <label for="password" class="form-label m-0">{{'labels.password' | translate}}</label>
                    <input formControlName="password" id="password" type="password" name="password" autocomplete="off"
                           required class="form-control ng-invalid ng-invalid-required">
                    <span class="text-danger"
                          *ngIf="!this.loginForm.get('password')?.valid && submitted">{{'errors.invalid.emptyPassword' | translate}}</span>
                </div>

                <div class="my-4 text-center">
                    <a class="text-muted"
                       [routerLink]="['/forgot-password']">{{'labels.forgotPassword' | translate}}</a>
                </div>

                <button class="btn btn-primary w-100" type="submit">{{'buttons.enter' | translate}}</button>
            </form>
        </div>
    </div>
</div>
