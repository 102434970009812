import {AfterViewInit, Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {CdkDrag, DragRef, Point} from "@angular/cdk/drag-drop";
import $ from 'jquery';

@Component({
    selector: 'app-webchat',
    templateUrl: './webchat.component.html',
    styleUrls: ['./webchat.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WebchatComponent implements AfterViewInit {
    @ViewChild(CdkDrag) private cdkDrag!: CdkDrag;
    iconForum: boolean = true;

    ngAfterViewInit(): void {
        this.cdkDrag.lockAxis = 'y';
        this.cdkDrag.constrainPosition = (
            userPointerPosition: Point,
            dragRef: DragRef,
            dimensions: ClientRect,
            pickupPositionInElement: Point
        ) => {
            return {
                x: 0,
                y: Math.max(0, Math.min(window.innerHeight - dimensions.height, userPointerPosition.y))
            };
        };
        this.setupEventHandlers();
    }

    private setupEventHandlers() {
        $('.btn-webchat').on('click', this.toggleChat);
        $('.item-toggle').on('click', this.toggleItem);
    }

    private toggleChat() {
        if ($('.btn-webchat').hasClass('opened')) {
            $('.iframe').removeClass('opened');
            $('.btn-webchat').removeClass('opened');
        } else {
            $('.iframe').addClass('opened');
            $('.btn-webchat').addClass('opened');
        }
    }

    private toggleItem(e: any) {
        $(this).toggleClass('actived');
    }

    changeIcon() {
        this.iconForum = $('.btn-webchat').hasClass('opened');
    }
}
