import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {MotorMessageService} from "../../../service/motor-message.service";
import {AuthenticationService} from '../authentication.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: false
})
export class ForgotPasswordComponent implements OnInit {
    hash: string | null = '';
    pattern: string = '(?=.{9,})((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[@#$%^*()!&=])|(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^*()!&=])).*';
    passwordMatches: boolean = false;
    submitted: boolean = false;
    beginForm: FormGroup;
    endForm: FormGroup;

    constructor(
        private authService: AuthenticationService,
        private motorMessage: MotorMessageService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.beginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });

        this.endForm = new FormGroup({
            newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.pattern)]),
            newPasswordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.pattern)])
        });
    }

    ngOnInit(): void {
        this.hash = this.route.snapshot.paramMap.get('hash');
    }

    submitBeginRecoveryPassword(): void {
        this.submitted = true;

        if (this.beginForm.valid) {
            this.beginForm.get('email');
            this.authService.sendBeginRecoveryPassword(this.beginForm.value.email).subscribe({
                next: () => {
                    this.motorMessage.openSnackBar('labels.emailSended');
                    this.router.navigate(['']).then();
                },
                error: () => {
                    this.beginForm.controls['email'].setErrors({'incorrect': true});
                }
            });
        }
    }

    submitEndRecoveryPassword(): void {
        this.submitted = true;

        if (this.endForm.valid && this.passwordMatches && this.hash) {
            this.authService.sendEndRecoveryPassword(this.endForm.value.newPassword, this.hash).subscribe({
                next: () => {
                    this.motorMessage.openSnackBar('labels.passwordChanged');
                    this.router.navigate(['']).then();
                }
            });
        }
    }

    passwordMarchVerify(): void {
        this.passwordMatches = this.endForm.get('newPassword')?.value === this.endForm.get('newPasswordConfirm')?.value;
    }
}
