import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from 'src/app/service/loader.service';
import { Subject, Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent implements OnInit, OnDestroy {
    isLoading: Subject<boolean> = this.loaderService.isLoading;
    progressValue: number = 0;
    loading: boolean = false;
    private interval: any;
    private subscription: Subscription = new Subscription();

    constructor(private loaderService: LoaderService, private router: Router) {}

    ngOnInit() {
        this.subscription.add(
            this.isLoading.subscribe(load => {
                if (load) {
                    this.startLoading();
                } else {
                    this.stopLoading();
                }
            })
        );

        this.subscription.add(
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.startLoading();
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError
                ) {
                    this.stopLoading();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.stopProgress();
    }

    startLoading() {
        this.loading = true;
        this.progressValue = 0;
        this.simulateProgress();
    }

    stopLoading() {
        this.loading = false;
        this.progressValue = 100;
        this.stopProgress();
    }

    simulateProgress() {
        this.stopProgress();
        this.interval = setInterval(() => {
            if (this.progressValue + 10 >= 100 && this.loading) {
                this.progressValue = 90;
            } else if (this.progressValue < 100) {
                this.progressValue += 10;
            } else {
                this.stopProgress();
            }
        }, 500);
    }

    stopProgress() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
}
