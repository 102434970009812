import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import {LocalstorageService} from 'src/app/shared/services/localstorage.service';
import {MotorMessageService} from "../../../service/motor-message.service";
import {AuthenticationService} from '../authentication.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent {
    submitted: boolean = false;
    loginForm: FormGroup;

    constructor(
        private readonly route: ActivatedRoute,
        private authService: AuthenticationService,
        private motorMessage: MotorMessageService,
        private lsService: LocalstorageService,
        private router: Router,
    ) {
        this.loginForm = new FormGroup({
            email: new FormControl('', {nonNullable: true}),
            password: new FormControl('', {nonNullable: true, validators: Validators.minLength(4)})
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
          const token = params['token'];
          if (token) {
            this.loginWithToken(token);
          }
        });
      }

    authentication() {
        this.submitted = true;

        if (this.loginForm.valid) {

            this.authService.authenticate(this.loginForm.value.email, this.loginForm.value.password).subscribe({
                next: (result: any) => {
                    this.lsService.setItem('user', JSON.stringify(result.data.user));
                    this.authService.currentUser.set(result.data.user);
                    this.authService.setCurrentUser();
                    this.router.navigate(['/home']);
                },
                error: httpError => this.motorMessage.openSnackBar(httpError.error.msg)
            });
        }
    }

    loginWithToken(token: string): void {
        this.authService.authenticateFederation(token).subscribe({
            next: (result: any) => {
                this.lsService.removeItem('user');        
                this.lsService.setItem('user', JSON.stringify(result.data.user));
                this.authService.currentUser.set(result.data.user);
                this.authService.setCurrentUser();
                this.router.navigate(['/home']);
            },
            error: httpError => this.motorMessage.openSnackBar(httpError.error.msg)
        });
    }
}
