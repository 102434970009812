<!DOCTYPE html>
<html lang="pt-BR">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>SLA</title>
    <link href="webchat.component.css" rel="stylesheet">
</head>
<body>

<div class="btn-webchat button-actions-primary" matTooltip="{{'labels.conversationWithMia' | translate}}" (click)="changeIcon()" cdkDrag
     cdkDragLockAxis="y">
    <mat-icon *ngIf="iconForum">forum</mat-icon>
    <mat-icon *ngIf="!iconForum">cancel</mat-icon>
</div>

<script type="text/javascript" src="./js/jquery-3.3.1.min.js"></script>
<!--<app-chat class="iframe"></app-chat>-->
<script type="text/javascript" src="./js/iframefunctionality.js"></script>
</body>
</html>
