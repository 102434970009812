<div class="main-wrapper">
    <header>
        <app-header (toggleMenu)="onToggleMenu($event)" [statusMenu]="statusMenu"></app-header>
    </header>
    <aside [ngClass]="{'menu-opened':statusMenu == 'opened'}" class="d-flex flex-column">
        <app-sidebar (onClickItem)="statusMenu = 'close'"></app-sidebar>

        <div class="mt-auto d-flex">
            <img src="/assets/images/logo-client-alt.png" alt="" class="logo-mones mx-auto my-3"/>
        </div>
    </aside>

    <section class="d-flex flex-column overflow-auto">
        <section class="flex-grow-1">
            <router-outlet></router-outlet>
        </section>
        <footer class="p-3 ms-auto">
            &copy; {{currentYear}} - Mones -
            <a href="./policy.html" target="_blank">{{'labels.privacy' | translate}}</a> -
            <a href="./terms.html" target="_blank"> {{'labels.terms' | translate}}</a>
        </footer>
    </section>
</div>
