import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {AuthenticationService} from 'src/app/pages/authentication/authentication.service';
import {IUser} from "../../../models/user.model";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnDestroy {
    @Output() toggleMenu = new EventEmitter<string>();
    @Input() statusMenu: string = 'closed';
    public currentUser: IUser = {};
    currentUserSub: Subscription;
    accessGroup: boolean = false;

    constructor(
        protected authService: AuthenticationService,
    ) {
        this.currentUserSub = this.authService.getCurrentUserObservable().subscribe(user => {
            this.currentUser = user
            this.accessGroup = !!user.access!.find(item => item === 'user_groups');
        });
    }

    ngOnDestroy(): void {
        if (this.currentUserSub) {
            this.currentUserSub.unsubscribe();
        }
    }

    onToggleMenu(): void {
        this.toggleMenu.emit((this.statusMenu == 'closed') ? 'opened' : 'closed');
    }

    logout(): void {
        this.authService.logout();
    }
}
