import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './shared/guards/auth.guard';
import {UnknownRoutesGuard} from './shared/guards/unknown-routes.guard';
import {LoginComponent} from './pages/authentication/login/login.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {MasterLayoutComponent} from './layouts/master-layout/master-layout.component';
import {ForgotPasswordComponent} from './pages/authentication/forgot-password/forgot-password.component';
import {NavigationService} from "./shared/interceptors/navigation.service";

const routes: Routes = [
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {path: '', component: LoginComponent, pathMatch: 'full'},
            {path: 'login/:token', component: LoginComponent },
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: 'forgot-password/:hash', component: ForgotPasswordComponent}
        ],
        canActivate: [UnknownRoutesGuard]
    },
    {
        path: '',
        component: MasterLayoutComponent,
        children: [
            {
                path: 'home',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'contact-list',
                loadChildren: () => import('./pages/contact-list/contacts-list.module').then(m => m.ContactsListModule)
            },
            {
                path: 'attribute-list',
                loadChildren: () => import('./pages/attribute-list/attribute-list.module').then(m => m.AttributeListModule)
            },
            {
                path: 'conversation-cognition',
                loadChildren: () => import('./pages/conversation-cognition/conversation-cognition.module').then(m => m.ConversationCognitionModule)
            },
            {
                path: 'log-track',
                loadChildren: () => import('./pages/log-track/log-track.module').then(m => m.LogTrackModule)
            },
            {
                path: 'account/profile',
                loadChildren: () => import('./pages/account-profile/account.module').then(m => m.AccountModule)
            },
            {
                path: 'recordings',
                loadChildren: () => import('./pages/recordings/recordings.module').then(m => m.RecordingsModule)
            },
            {
                path: 'api-data',
                loadChildren: () => import('./pages/api-data/api-data.module').then(m => m.ApiDataModule)
            },
            {
                path: 'entity',
                loadChildren: () => import('./pages/entity/entity.module').then(m => m.EntityModule)
            },
            {
                path: 'status-delivery-hsm',
                loadChildren: () => import('./pages/status-delivery-hsm/status-delivery-hsm.module').then(m => m.StatusDeliveryHsmModule)
            },
            {
                path: 'quiz',
                loadChildren: () => import('./pages/quiz/quiz.module').then(m => m.QuizModule)
            },
            {
                path: 'conversation',
                loadChildren: () => import('./pages/conversation/conversation.module').then(m => m.ConversationModule)
            },
            {
                path: 'dialog',
                loadChildren: () => import('./pages/dialog/dialog.module').then(m => m.DialogModule)
            },
            {
                path: 'quiz-analyze',
                loadChildren: () => import('./pages/quiz-analyze/quiz-analyze.module').then(m => m.QuizAnalyzeModule)
            },
            {
                path: 'quicksight',
                loadChildren: () => import('./pages/quicksight/quicksight.module').then(m => m.QuicksightModule)
            },
            {
                path: 'template-whatsapp',
                loadChildren: () => import('./pages/template-whatsapp/template-whatsapp.module').then(m => m.TemplateWhatsappModule)
            },
            {
                path: 'user-groups',
                loadChildren: () => import('./pages/user-groups/user-groups.module').then(m => m.UserGroupsModule)
            },
            {
                path: 'workflow-execution',
                loadChildren: () => import('./pages/workflow-execution/workflow-execution.module').then(m => m.WorkflowExecutionModule)
            },
            {
                path: 'execution-consultation',
                loadChildren: () => import('./pages/message-sending/message.module').then(m => m.MessageModule)
            },
            {
                path: 'loadable-entity',
                loadChildren: () => import('./pages/loadable-entity/loadable-entity.module').then(m => m.LoadableEntityModule)
            },
            {
                path: 'action-system',
                loadChildren: () => import('./pages/action-system/action-system.module').then(m => m.ActionSystemModule)
            },
            {
                path: 'bot',
                loadChildren: () => import('./pages/bot/bot.module').then(m => m.BotModule)
            },
            {
                path: 'ai',
                loadChildren: () => import('./pages/ai/ai.module').then(m => m.AiModule)
            },
            {
                path: 'api',
                loadChildren: () => import('./pages/api/api.module').then(m => m.ApiModule)
            },
            {
                path: 'training-conversation',
                loadChildren: () => import('./pages/training-conversation/training-conversation.module').then(m => m.TrainingConversationModule)
            },
            {
                path: 'curation-tag',
                loadChildren: () => import('./pages/curation-tag/curation-tag.module').then(m => m.CurationTagModule)
            },
            // {
            //     path: 'template-hsm',
            //     loadChildren: () => import('./pages/template-hsm/template-hsm.module').then(m => m.TemplateHsmModule)
            // },
        ],
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/home'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [NavigationService]
})
export class AppRoutingModule {
    constructor(private readonly navigationService: NavigationService) {}
}
