import {Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from 'src/app/pages/authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements OnDestroy {
    public userName: string = '';
    userNameSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
    ) {
        this.userNameSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.userName = user.name!);
    }

    ngOnDestroy(): void {
        if (this.userNameSub) {
            this.userNameSub.unsubscribe();
        }
    }

    canActivate(state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.userName) {
            return true;
        }

        this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
