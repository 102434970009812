import {Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LocalstorageService} from "../services/localstorage.service";

@Injectable({
    providedIn: 'root'
})
export class UnknownRoutesGuard {
    constructor(
        private lsService: LocalstorageService,
        private router: Router
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const url: string = state.root.fragment || '';

        if (state.url.indexOf('/login/') >= 0) {
            this.lsService.removeItem('user');
        }

        if (this.lsService.getItem('user')) {
            this.router.navigate(['home']).then();
        }
        if (url.indexOf('!/auth/recovery/') >= 0) {
            const hash: string = url.substring(16);
            this.router.navigate(['forgot-password/' + hash]).then();
            return false;
        } else {
            return true;
        }
    }
}
