<div class="container py-3">
    <div class="row mb-3">
        <div class="col">
            <h4 class="fw-normal">{{'labels.recoveryPasswod' | translate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <form *ngIf="!hash" [formGroup]="beginForm" (ngSubmit)="submitBeginRecoveryPassword()">
                <div class="form-group has-feedback"
                     [ngClass]="{'text-danger':submitted && beginForm.get('email')?.errors}">
                    <label for="email" class="form-label m-0">{{'inputs.placeholder.email' | translate}} *</label>
                    <input formControlName="email" class="form-control ng-valid-email ng-invalid ng-invalid-required"
                           type="email" name="email" id="email" autocomplete="off"/>
                    <span class="text-danger"
                          *ngIf="submitted && !beginForm.valid">{{ 'errors.invalid.email' | translate }}</span>
                </div>

                <button class="btn btn-primary w-100 mt-4" type="submit">{{'buttons.send' | translate}}</button>
            </form>

            <form *ngIf="hash" [formGroup]="endForm" (ngSubmit)="submitEndRecoveryPassword()">
                <div class="form-group has-feedback end-form">
                    <input (input)="passwordMarchVerify()" formControlName="newPassword" class="form-control"
                           type="password" placeholder="{{'labels.newPassword' | translate}}" autocomplete="off"/>
                    <input (input)="passwordMarchVerify()" formControlName="newPasswordConfirm"
                           class="mt-2 form-control"
                           type="password" placeholder="{{'labels.newConfirmPassword' | translate}}"
                           autocomplete="off"/>
                    <span class="text-danger m-2 d-block"
                          *ngIf="(submitted || endForm.get('newPassword')?.value) && endForm.get('newPassword')?.errors">{{'errors.invalid.passwordPattern' | translate}}</span>
                    <span class="text-danger m-2 d-block"
                          *ngIf="submitted && !passwordMatches">{{'errors.invalid.matchPassword' | translate}}</span>
                </div>

                <button class="btn btn-primary w-100 mt-4" type="submit">{{'buttons.send' | translate}}</button>
            </form>

            <div class="text-center">
                <a class="btn btn-block btn-default ng-binding" [routerLink]="['/']">{{'buttons.back' | translate}}</a>
            </div>
        </div>
    </div>
</div>
