import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {MenuService} from 'src/app/shared/services/menu.service';
import {filter, Subscription} from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit {
    @Output() onClickItem = new EventEmitter<string>();
    menuService = inject(MenuService);
    private routerSubscription!: Subscription;
    router = inject(Router);
    menuItems: Array<any> = [];

    ngOnInit(): void {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof RoutesRecognized))
            .subscribe((event: any) => {
            // hide all submenus
            this.hideAllSubmenus();

            // scroll view to top
            window.scrollTo(0, 0);

            // close sidebar on route change
            this.onClickItem.emit(event.url);
        });

        this.menuItems = this.menuService.reload();
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    toggleSubmenuHover(event: any): void {
        this.hideAllSubmenus();
        let li: HTMLElement = event.target.parentElement;
        li.classList.add('hover');
    }

    hideAllSubmenus(): void {
        const items = document.querySelectorAll('li.hover');
        items.forEach(item => {
            item.classList.remove('hover');
        });
    }
}
