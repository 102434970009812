import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {LoaderService} from "../../service/loader.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    constructor(private router: Router, private loaderService: LoaderService) {
        this.router.events.pipe(
            filter(event => {
                return event instanceof NavigationEnd && event.url === '/home';
            })
        ).subscribe(() => {
            setTimeout(() => {
                this.loaderService.hide();
            }, 0);
        });
    }
}
