<div class="login-wrapper">
    <div class="column-1">
        <header>
            <img src="/assets/images/logo-omotor.png" alt="Logo OMotor">
        </header>

        <div class="login-box">
            <router-outlet></router-outlet>
        </div>

        <footer>
            <img src="/assets/images/logo-client.png" alt="Logo">
        </footer>
    </div>
    <div class="column-2"></div>
</div>
