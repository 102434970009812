import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MasterLayoutComponent} from './master-layout/master-layout.component';
import {LoaderComponent} from '../shared/component/loader/loader.component';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {WebchatComponent} from "../pages/webchat/webchat.component";
import {SidebarComponent} from './parts/sidebar/sidebar.component';
import {HeaderComponent} from './parts/header/header.component';
import {MenuService} from '../shared/services/menu.service';
import {SharedModule} from "../shared/shared.module";

@NgModule({
    declarations: [
        MasterLayoutComponent,
        AuthLayoutComponent,
        WebchatComponent,
        SidebarComponent,
        HeaderComponent,
        LoaderComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
    ],
    providers: [
        MenuService
    ],
})
export class LayoutsModule {
}
