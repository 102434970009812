import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from 'src/app/pages/authentication/authentication.service';
import {IUser} from 'src/app/models/user.model';
import {environment} from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnDestroy {
    public currentUser: IUser = {};
    currentUserSub: Subscription;

    constructor(
        private authService: AuthenticationService,
    ) {
        this.currentUserSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.currentUser = user);
    }

    ngOnDestroy(): void {
        if (this.currentUserSub) {
            this.currentUserSub.unsubscribe();
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excludedEndpoints: string[] = environment.excludedTokenEndpoints || [];

        if (this.currentUser && this.currentUser.token && !excludedEndpoints.some(endpoint => request.url.includes(endpoint))) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.currentUser.token}`
                }
            });
        }

        return next.handle(request);
    }
}
