import {IMenuItem} from '../models/menu-item.model';

const headingMain = {
    text: '',
    heading: true,
};

const Home: IMenuItem = {
    text: 'labels.home',
    link: '/home',
    icon: 'home',
    role: '',
};

const ContactList: IMenuItem = {
    text: 'labels.lists',
    link: '/contact-list',
    icon: 'list',
    role: 'contact_list'
};

const Parameters: IMenuItem = {
    text: 'labels.params',
    link: '',
    icon: 'tune',
    submenu: [
        {
            text: 'labels.attribute',
            icon: 'badge',
            link: '/attribute-list',
            role: 'contact_groups'
        },
        {
            text: 'labels.entity',
            icon: 'settings_suggest',
            link: '/entity',
            role: 'entity'
        },
        {
            text: 'labels.api',
            icon: 'api',
            link: '/api',
            role: 'api'
        },
        {
            text: 'labels.apidata',
            icon: 'data_object',
            link: '/api-data',
            role: 'apidata'
        }
        // {
        //     text: 'labels.template.hsm',
        //     icon: 'chat_bubble',
        //     link: '/template-hsm',
        //     role: 'conversation'
        // }
    ],
    role: ''
};

const Integrations: IMenuItem = {
    text: 'labels.interactions',
    link: '',
    icon: 'hub',
    submenu: [
        {
            text: 'labels.dialog',
            icon: 'chat',
            link: '/dialog',
            role: 'dialog'
        },
        {
            text: 'labels.conversation',
            icon: 'forum',
            link: '/conversation',
            role: 'conversation'
        },
        {
            text: 'labels.quiz',
            icon: 'quiz',
            link: '/quiz',
            role: 'quiz'
        }
    ],
    role: ''
};

const Transactions: IMenuItem = {
    text: 'labels.transactions',
    link: '',
    icon: 'compare_arrows',
    submenu: [
        {
            text: 'labels.sendMessages',
            icon: 'event_available',
            link: '/execution-consultation',
            role: 'execution_consultation'
        },
        {
            text: 'labels.workflowExecution',
            icon: 'info_outline',
            link: '/workflow-execution',
            role: 'workflow_execution'
        },
        {
            text: 'labels.recordings',
            icon: 'sd_card',
            link: '/recordings',
            role: 'recordings'
        },
        {
            text: 'labels.analyzeQuiz',
            icon: 'quiz',
            link: '/quiz-analyze',
            role: 'quiz'
        },
    ],
    role: ''
};

const Cognition: IMenuItem = {
    text: 'labels.cognition',
    link: '',
    icon: 'school',
    submenu: [
        {
            text: 'labels.trainnings',
            icon: 'play_lesson',
            link: '/training-conversation',
            role: 'trainning'
        },
        {
            text: 'labels.dialogCognition',
            icon: 'record_voice_over',
            link: '/conversation-cognition',
            role: 'dialog_cognition'
        },
    ],
    role: ''
};

const ai: IMenuItem = {
    text: 'labels.generativeAI',
    link: '',
    icon: 'select_all',
    submenu: [
        {
            text: 'labels.curatorship',
            icon: 'dvr',
            link: 'ai/curation',
            role: 'curation_gpt'
        },
        {
            text: 'labels.trainingLibrary',
            icon: 'nfc',
            link: 'ai/embedding',
            role: 'embedding_gpt'
        },
        {
            text: 'labels.IntentTag',
            icon: 'label',
            link: 'ai/tags',
            role: 'tags_gpt'
        },
        {
            text: 'labels.console.gpt',
            icon: 'dvr',
            link: 'ai/web',
            role: 'console_gpt'
        },
    ],
    role: ''
};

const Analytics: IMenuItem = {
    text: 'labels.quicksight',
    link: '/quicksight',
    icon: 'bar_chart',
    role: 'quicksight'
};

const Logs: IMenuItem = {
    text: 'labels.logs',
    link: '',
    icon: 'storage',
    submenu: [
        {
            text: 'labels.logtrack',
            icon: 'info',
            link: '/log-track',
            role: 'logtrack'
        },
        {
            text: 'labels.templateWhatsappDeliveryStatus',
            icon: 'send',
            link: '/status-delivery-hsm',
            role: 'template_whatsapp_delivery_status'
        },
    ],
    role: ''
};

export const menuArray = [
    headingMain,
    Home,
    ContactList,
    Parameters,
    Integrations,
    Transactions,
    Cognition,
    ai,
    Analytics,
    Logs,
];
