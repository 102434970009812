<div class="aside-inner">
    <nav class="sidebar">
        <ul class="nav">
            <li *ngFor='let item of menuItems' [routerLinkActive]="['active']">
                <!-- external links -->
                <a *ngIf="!item.heading && !item.submenu && item.elink" [attr.target]="item.target"
                   [attr.href]="item.elink" title="{{item.text | translate}}">
                    <span class="pull-right" *ngIf="item.alert"
                          [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
                    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                    <span>{{item.text | translate}}</span>
                </a>

                <!-- single menu item -->
                <a *ngIf="!item.heading && !item.submenu && !item.elink" [routerLink]="item.link"
                   [attr.route]="item.link" title="{{item.text | translate}}"
                   (mouseenter)="toggleSubmenuHover($event)">
                    <span class="pull-right" *ngIf="item.alert"
                          [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
                    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                    <span>{{item.text | translate}}</span>
                </a>

                <!-- has submenu -->
                <a *ngIf="!item.heading && item.submenu" title="{{item.text! | translate}}"
                   (mouseenter)="toggleSubmenuHover($event)">
                    <span class="pull-right" *ngIf="item.alert"
                          [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
                    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                    <span>{{item.text | translate}}</span>
                </a>

                <!-- SUBLEVEL -->
                <ul *ngIf="item.submenu" class="nav sidebar-subnav" [routerLinkActive]="['opening']">
                    <li *ngFor='let subitem of item.submenu' [routerLinkActive]="['active']">
                        <!-- sublevel: external links -->
                        <a *ngIf="!subitem.heading && !subitem.submenu && subitem.elink" [attr.target]="subitem.target"
                           [attr.href]="subitem.elink" title="{{subitem.text | translate}}">
                            <span class="pull-right" *ngIf="subitem.alert"
                                  [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                            <mat-icon *ngIf="subitem.icon">{{subitem.icon}}</mat-icon>
                            <span>{{subitem.text | translate}}</span>
                        </a>

                        <!-- sublevel: single menu item  -->
                        <a *ngIf="!subitem.submenu && !subitem.elink" [routerLink]="subitem.link"
                           [attr.route]="subitem.link" title="{{subitem.text | translate}}">
                            <span class="pull-right" *ngIf="subitem.alert"
                                  [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                            <mat-icon *ngIf="subitem.icon">{{subitem.icon}}</mat-icon>
                            <span>{{subitem.text | translate}}</span>
                        </a>

                        <!-- sublevel: has submenu -->
                        <a *ngIf="subitem.submenu" title="{{subitem.text}}"
                           (mouseenter)="toggleSubmenuHover($event)">
                            <span class="pull-right" *ngIf="subitem.alert"
                                  [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                            <mat-icon *ngIf="subitem.icon">{{subitem.icon}}</mat-icon>
                            <span>{{subitem.text | translate}}</span>
                        </a>

                        <!-- SUBLEVEL 2 -->
                        <ul *ngIf="subitem.submenu" class="nav sidebar-subnav level2" [routerLinkActive]="['opening']">
                            <li *ngFor='let subitem2 of subitem.submenu' [routerLinkActive]="['active']">
                                <!-- sublevel 2: single menu item  -->
                                <a *ngIf="!subitem2.submenu" [routerLink]="subitem2.link" [attr.route]="subitem2.link"
                                   title="{{subitem2.text | translate}}">
                                    <span class="pull-right" *ngIf="subitem2.alert"
                                          [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                                    <mat-icon *ngIf="subitem2.icon">{{subitem2.icon}}</mat-icon>
                                    <span>{{subitem2.text | translate}}</span>
                                </a>
                                <!-- sublevel2: has submenu -->
                                <a *ngIf="subitem2.submenu" title="{{subitem2.text}}"
                                   (mouseenter)="toggleSubmenuHover($event)">
                                    <span class="pull-right" *ngIf="subitem2.alert"
                                          [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                                    <mat-icon *ngIf="subitem2.icon">{{subitem2.icon}}</mat-icon>
                                    <span>{{subitem2.text | translate}}</span>
                                </a>
                            <li>
                        </ul>
                    <li>
                </ul>
            </li>
        </ul>
    </nav>
</div>
