import {Injectable, OnDestroy} from '@angular/core';
import {AuthenticationService} from 'src/app/pages/authentication/authentication.service';
import {IMenuItem} from '../../models/menu-item.model';
import {menuArray} from '../menuArray';
import {Subscription} from "rxjs";

@Injectable()
export class MenuService implements OnDestroy {
    menuItems: Array<IMenuItem> = [];
    accessSub: Subscription;
    access: string[] = [];

    constructor(
        private authService: AuthenticationService,
    ) {
        this.accessSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.access = user.access!);
    }

    ngOnDestroy(): void {
        if (this.accessSub) {
            this.accessSub.unsubscribe();
        }
    }

    addItemsToMenu(items: Array<IMenuItem>): IMenuItem[] {
        items.forEach(item => {
            if (item.submenu) {
                item.submenu = item.submenu.filter(subItem => subItem.role == '' || this.access.find(a => a == subItem.role));
            }
            if (item.role == '' || this.access.find(a => a == item.role)) {
                if (item.submenu && item.submenu.length === 0) {
                    return;
                }
                this.menuItems.push(item);
            }
        });
        return this.menuItems;
    }

    reload(): IMenuItem[] {
        this.menuItems = [];
        return this.addItemsToMenu(JSON.parse(JSON.stringify(menuArray)));
    }
}
