import {Component, inject} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
    title = 'mones';
    iconRegistry = inject(MatIconRegistry);

    constructor() {
        this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    }
}
